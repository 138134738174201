import React from "react";

import { navigate } from "gatsby";

const RevcoreContactPage = () => {
  const navTo = (e, dest) => {
    e.preventDefault();
    navigate(dest, { state: { showError: false } });
  };

  return (
    <section
      className="has-background-white"
      style={{ padding_bottom: "140px" }}
    >
      <div className="section pb-2 has-background-dark has-text-white">
        <div className="container">
          <div className="columns is-multiline is-centered ">
            <div className="column is-8 is-6-desktop">
              <div className="mx-auto mb-5 has-text-centered">
                <span className="has-text-white">Get In Touch</span>
                <h2 className="mt-2 mb-3 is-size-1 is-size-3-mobile has-text-weight-bold">
                  Want to talk?
                </h2>
                <p className="has-text-white">
                  Always happy to talk or answer questions about Grin, crypto-currency
                  technology, security, cryptography or anything at all!
                </p>
              </div>
            </div>
            <div className="column is-12 is-8-desktop">
              <div className="columns is-multiline">
                <div className="column is-4 mb-4">
                  <div
                    className="box py-6 has-background-light has-text-centered"
                    style={{ height: "100%" }}
                  >
                    <h3 className="is-size-4 has-text-weight-bold">Github</h3>
                    <p className="mt-3 mb-1">
                      <a href="https://github.com/yeastplume">Yeastplume's Github Profile</a>
                    </p>
                  </div>
                </div>
                <div className="column is-4 mb-4">
                  <div
                    className="box py-6 has-background-light has-text-centered"
                    style={{ height: "100%" }}
                  >
                    <h3 className="is-size-4 has-text-weight-bold">Email</h3>
                    <p className="mt-3 mb-1">contact@revcore.net</p>
                  </div>
                </div>
                <div className="column is-4 mb-4">
                  <div
                    className="box py-6 has-background-light has-text-centered"
                    style={{ height: "100%" }}
                  >
                    <h3 className="is-size-4 has-text-weight-bold">
                      Messaging
                    </h3>
                    <p className="mt-3 mb-1">
                      <a href="https://keybase.io/yeastplume">Keybase</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RevcoreContactPage;
