import * as React from "react";
import "../scss/bulma-theme.scss";

import Layout from "../components/common/layout";
import RevcoreContactPage from "../components/frontend/contact/contact"

const ContactPage = () => {

  
  return (
    <Layout>
      <RevcoreContactPage/>
    </Layout>
  );
};

export default ContactPage;
